<template>
  <div class="items-center jumbotron min-vh-100">
    <div class="w-full">
      <div class="">
        <!-- eslint-disable-next-line vue/require-component-is -->
        <component :is="errorPage" :error="error" />
      </div>
    </div>
  </div>
</template>

<script setup>
import error404 from '~/components/errors/404'
import error500 from '~/components/errors/500'
import error503 from '~/components/errors/503'

definePageMeta({
  layout: 'blank',
})

const props = defineProps({
  error: {
    type: Object,
    default: () => {},
  },
})

const errorPage = computed(() => {
  if (props.error.statusCode === 404) {
    return error404
  } else if (props.error.statusCode === 503) {
    return error503
  }

  // catch everything else
  return error500
})
</script>

<style lang="scss">
body {
  background-color: white !important;
}
</style>
